export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart assistant app to detect, analyze, prevent epilepsy seizures and beyond"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Vue i18n next example using vue-i18n-next"])},
  "menu_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
  "menu_features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Features"])},
  "menu_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
  "menu_questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions"])},
  "menu_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
  "menu_donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donation to the app"])},
  "bottom_menu_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
  "bottom_menu_terms_of_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Use"])},
  "bottom_menu_cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies"])},
  "bottom_menu_legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal"])},
  "use_for_free_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use for free!"])},
  "use_for_free_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EpiHelp improves quality of life"])},
  "install_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install now"])},
  "features_div": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Features designed to improve the quality of life "])},
  "features_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart App helps to avoid unpleasant situations."])},
  "includes_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Includes all necessary functions to accumulate information and control the disorder."])},
  "v_all_features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View All Features"])},
  "app_features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Features"])},
  "smart_algorith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart algorithm. How does he work"])},
  "the_epihelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The EpiHelp mobile application collects and processes medical data"])},
  "the_epihelp2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["using two devices: an iPhone and an Apple Watch."])},
  "the_basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" The basic data required for analysis and"])},
  "the_basic2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transmitted from the Apple Watch:"])},
  "heart_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heart rate"])},
  "pedometer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedometer"])},
  "ecg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electrocardiogram (ECG)(On models"])},
  "ecg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["older than WatchOS 5)"])},
  "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])},
  "add_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional data:"])},
  "altitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altitude (On models older than WatchOS 5)"])},
  "sleep_monitoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sleep monitoring (If the user has activated this function)"])},
  "user_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User data"])},
  "predicting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predicting A Seizure & Warning The User About It"])},
  "predicting2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
  "unique_authorship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique authorship"])},
  "the_seizure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The seizure prediction model analyzes the data received from the Apple Watch, iPhone sensors, and the data entered manually: either by you or your physician. Based on your medical record, the app predicts the type and characteristics of a potential attack"])},
  "seizure_tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seizure tracking."])},
  "the_mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The mobile app can track two types of falls:"])},
  "fainting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fainting"])},
  "falling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falling in a seizure."])},
  "at_the": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At the time of a fall, the app automatically notifies all the trusted people you have marked as caregivers."])},
  "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert system"])},
  "alert_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The alert system works through push notifications (You have to turn on this function) Transmitting seizure probability data to loved ones (If the user activates this feature) Several methods to inform loved ones during a seizure:"])},
  "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text (SMS)"])},
  "phone_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Call"])},
  "phone_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Call Retry Attempts"])},
  "app_store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Store reviews"])},
  "view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all reviews"])},
  "support_24/7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support 24/7"])},
  "choose_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a category below to get started"])},
  "mem_acc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membership and Account"])},
  "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question"])},
  "app_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Setup and Using"])},
  "feature_re": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feature Request"])},
  "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports and Payments"])},
  "lost_passwords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lost Password"])},
  "report_concern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report a Concern"])},
  "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
  "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us:"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
  "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover F.A.Q"])},
  "l_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave feedback — "])},
  "opinion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your opinion matters"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "the_technical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The technical support team helps you with "])},
  "the_technical2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["any operational application-related issue."])},
  "step_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step-by-step support: launch, settings, testing assistance"])},
  "get_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Step-by-Step Directions"])},
  "our_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our contact"])},
  "clevend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cleveland"])},
  "you_can": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can find the most asked question below. If you have a question that is not listed, please contact us for details."])},
  "follow_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow us:"])},
  "our_e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our e-mail:"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our phone:"])},
  "write_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write us"])},
  "cooptright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright © 2021 EpiHelp. All Rights Reserved."])},
  "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
  "terms_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Use"])},
  "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies"])},
  "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "membership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membership and Account"])},
  "our_personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our personal consultants will take"])},
  "our_personal1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you step by step through the process."])},
  "how_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to contact you?"])},
  "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your name"])},
  "email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
  "descript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "your_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your phone number"])},
  "publications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publications"])},
  "neurolog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEUROLOGICAL DISORDERS AND CONDITIONS OF CEREBRAL HEMODYNAMICS IN PATIENTS WITH DYSCIRCULATORY ENCEPHALOPATHY ON THE BACKGROUND OF ARTERIALHYPOTENSION"])},
  "esy_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easy-to-use"])},
  "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community"])},
  "send_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully Sent"])}
}