<!-- eslint-disable no-tabs -->
<template>
	<div class="s1">
		<div class="container">
			<div class="s1__box">
				<div>
					<div class="s1__img">
						<img src="https://firebasestorage.googleapis.com/v0/b/epi-help.appspot.com/o/companybanderdiv%2Fdevice.png?alt=media&token=d49fea55-fa76-4050-a1fc-5618f075d10d" alt="" title=""
							draggable="false">
					</div>
				</div>
				<div>

					<div class="s1__main">
						<h2 class="stitle stitle_noline s1__title-big">
							{{ $t('title') }}
						</h2>
						<ul class="s1__logos">
							<li>
								<img src="https://firebasestorage.googleapis.com/v0/b/epi-help.appspot.com/o/companybanderdiv%2Fiwatch.png?alt=media&token=de61c6e8-f765-4c99-9341-6e375a820473" alt="" title=""
									draggable="false">
							</li>
							<li>
								<img src="https://firebasestorage.googleapis.com/v0/b/epi-help.appspot.com/o/companybanderdiv%2Fappstore.png?alt=media&token=48a00e7f-3e3f-4457-8c6c-363a7a640b2c" alt="" title=""
									draggable="false">
							</li>
						</ul>

						<div class="s1__install-box">
							<p class="s1__install-text">IPhone &amp; Apple Watch friendly</p>
                        <a href="https://apps.apple.com/uz/app/epi-help/id1566594294" class="sbtn s1__install-btn" draggable="false">{{ $t('install_now') }}</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
