<template>
    <div class="s7">
        <div class="container">
            <h3 class="stitle s7__title">{{$t("app_store")}}</h3>
            <ul class="s7__items">

                <li v-for="{ id, content, image } in features" :key="id">
                    <div class="s7-item">
                        <div>
                            <div class="s7-item__img">
                                <img :src="image" alt="" title="" draggable="false">
                            </div>
                        </div>
                        <div>
                            <p class="s7-item__text">
                                <b>{{ content[$i18n.locale].title }}</b>
                                <span v-html="content[$i18n.locale].description"></span>
                            </p>
                        </div>
                    </div>
                </li>
            </ul>
            <a href="/reviews" class="sbtn s7__view-all" draggable="false">{{$t("view_all")}}</a>
        </div>
    </div>
</template>
<script>
import { useLoadReviews } from '@/firebase'
export default {
  setup () {
    const features = useLoadReviews(4)
    return { features }
  }
}
</script>
