<template>
    <div class="s5">
        <div class="container">
            <div class="s5-box">
                <div class="s5-box__line"></div>
                <div class="s5-box__main">
                <h3 class="s5-box__title">{{ $t('use_for_free_title')}}</h3>
                <p class="s5-box__text">{{$t('use_for_free_body')}}</p>
                </div>
                <a href="https://apps.apple.com/uz/app/epi-help/id1566594294" class="sbtn sbtn_inv s5-box__btn"
                draggable="false">{{$t('install_now')}}</a>
            </div>
        </div>
    </div>
</template>
