/* eslint-disable spaced-comment */
/* eslint-disable indent */
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import { ref, onUnmounted } from 'vue'
const config = {
  apiKey: 'AIzaSyAx0tpItthv56704OqmcShQa1d0gPkjqqk',
  authDomain: 'epi-help.firebaseapp.com',
  projectId: 'epi-help',
  storageBucket: 'epi-help.appspot.com',
  messagingSenderId: '680627327579',
  appId: '1:680627327579:web:0d2c15552d946684eae57e',
  measurementId: 'G-CVYG0CE8PM'
}

const firebaseApp = firebase.initializeApp(config)

const db = firebaseApp.firestore()

const questionsCollection = db.collection('questions')

export const useLoadQuestions = (lim) => {
  const data = ref([])
  const close = questionsCollection.limit(lim).onSnapshot(snapshot => {
    data.value = snapshot.docs.map(
      doc => ({
      id: doc.id,
      content: {
        ru: { title: doc.data().title_ru, description: doc.data().description_ru },
        en: { title: doc.data().title_en, description: doc.data().description_en },
        uz: { title: doc.data().title_uz, description: doc.data().description_uz }
    }
    }))
  })
  onUnmounted(close)
  console.log(data)

  return data
}

const reviewsCollection = db.collection('reviews')

export const useLoadReviews = (lim) => {
  const data = ref([])
  const close = reviewsCollection.limit(lim).onSnapshot(snapshot => {
    data.value = snapshot.docs.map(
      doc => ({
      id: doc.id,
      image: doc.data().image,
      content: {
        ru: { title: doc.data().title, description: doc.data().description },
        en: { title: doc.data().title, description: doc.data().description },
        uz: { title: doc.data().title, description: doc.data().description }
    }
    }))
  })
  onUnmounted(close)

  return data
}
const supportCollection = db.collection('collaborations')

export const useLoadSupport = (lim) => {
  const data = ref([])
  const close = supportCollection.limit(lim).onSnapshot(snapshot => {
    data.value = snapshot.docs.map(
      doc => ({
      id: doc.id,
      image: doc.data().image,
      content: {
        ru: { title: doc.data().title_ru, description: doc.data().description_ru },
        en: { title: doc.data().title_en, description: doc.data().description_en },
        uz: { title: doc.data().title_uz, description: doc.data().description_uz }
    }
    }))
  })
  onUnmounted(close)

  return data
}

const featuresCollection = db.collection('features')

export const useLoadFeatures = (lim) => {
  const data = ref([])
  const close = featuresCollection.limit(lim).onSnapshot(snapshot => {
    data.value = snapshot.docs.map(
      doc => ({
      id: doc.id,
      image: doc.data().image,
      content: {
        ru: { title: doc.data().title_ru, description: doc.data().description_ru },
        en: { title: doc.data().title_en, description: doc.data().description_en },
        uz: { title: doc.data().title_uz, description: doc.data().description_uz }
    }
    }))
  })
  onUnmounted(close)

  return data
}

export const getPageFeature = async id => {
  const page = await featuresCollection.doc(id).get()
  // eslint-disable-next-line spaced-comment
  //console.log(page.data().title_ru)
  //alert(page.data.title_ru)
  return page.exists ? page.data() : null
}

const staticPageCollection = db.collection('staticpages')

export const getPage = async id => {
  const page = await staticPageCollection.doc(id).get()
  // eslint-disable-next-line spaced-comment
  //console.log(page.data().title_ru)
  //alert(page.data.title_ru)
  return page.exists ? page.data() : null
}

const privacyCollection = db.collection('staticpages')

export const useLoadPrivacy = () => {
  const data = ref([])
  const close = privacyCollection.onSnapshot(snapshot => {
    data.value = snapshot.docs.map(
      doc => ({
      id: doc.id,
      content: {
        ru: { description: doc.data().description_ru },
        en: { description: doc.data().description_en },
        uz: { description: doc.data().description_uz }
    }
    }))
  })
  onUnmounted(close)
  return data
}
const donationsCollection = db.collection('donations')

export const createDonation = donation => {
  return donationsCollection.add(donation)
}
const subscribeCollection = db.collection('subscribe')

export const createSubscribe = subscribe => {
  return subscribeCollection.add(subscribe)
}
const feedbackCollection = db.collection('feedback')

export const createFeedback = feedback => {
  return feedbackCollection.add(feedback)
}
const supportsCollection = db.collection('supports')

export const createSupport = user => {
  return supportsCollection.add(user)
}
const usersCollection = db.collection('users')

export const createUser = user => {
  return usersCollection.add(user)
}

export const getUser = async id => {
  const user = await usersCollection.doc(id).get()
  return user.exists ? user.data() : null
}

export const updateUser = (id, user) => {
  return usersCollection.doc(id).update(user)
}

export const deleteUser = id => {
  return usersCollection.doc(id).delete()
}

export const useLoadUsers = () => {
  const users = ref([])
  const close = usersCollection.onSnapshot(snapshot => {
    users.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  })
  onUnmounted(close)
  return users
}
