
<template>
    <footer class="footer">
        <div class="container">
            <div class="footer__box">
                <div>
                    <div class="footel__left">
                        <div class="footer__follow">
                            <b class="footer__heading">{{$t("follow_us")}}</b>
                            <ul class="footer__socials">

                                <li>
                                    <a href="#" target="_blank" title="Facebook" draggable="false">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/epi-help.appspot.com/o/icons%2FFacebook%20(1).svg?alt=media&token=6bac4064-1b73-41bd-99e5-bf24ab50c489" alt=""
                                            title="" draggable="false">
                                    </a>
                                </li>

                                <li>
                                    <a href="#" target="_blank" title="Twitter" draggable="false">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/epi-help.appspot.com/o/icons%2FTwitter.svg?alt=media&token=2c747da7-3e76-40f1-ad6e-43324a5dd3be" alt=""
                                            title="" draggable="false">
                                    </a>
                                </li>

                                <li>
                                    <a href="#" target="_blank" title="Instagram" draggable="false">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/epi-help.appspot.com/o/icons%2FInstagram.svg?alt=media&token=a8c41b16-eaff-443b-b533-94da88f0dfae" alt=""
                                            title="" draggable="false">
                                    </a>
                                </li>

                                <li>
                                    <a href="#" target="_blank" title="Youtube" draggable="false">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/epi-help.appspot.com/o/icons%2FYouTube.svg?alt=media&token=8d0ed863-d586-4e04-b87f-495e45b4659e" alt=""
                                            title="" draggable="false">
                                    </a>
                                </li>

                            </ul>
                        </div>
                        <div class="footer__contact">
                            <b class="footer__heading">{{$t("contact_us")}}</b>

                            <ul class="footer__contacts">
                                <li>
                                    <a href="#" title="Phone" draggable="false">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/epi-help.appspot.com/o/icons%2FPhone.svg?alt=media&token=48ebd553-7a4c-409f-9709-851010553783" alt=""
                                            title="" draggable="false">
                                    </a>
                                </li>

                                <li>
                                    <a href="#" title="Email" draggable="false">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/epi-help.appspot.com/o/icons%2Femail.svg?alt=media&token=4193606f-91fc-4395-a766-af0508721028" alt=""
                                            title="" draggable="false">
                                    </a>
                                </li>

                            </ul>

                        </div>
                    </div>
                </div>
                <div>
                    <a href="/" class="footer__logo" draggable="false">
                        <img src="https://firebasestorage.googleapis.com/v0/b/epi-help.appspot.com/o/icons%2FLogo.svg?alt=media&token=00cb3fd2-28dd-4e1d-b85a-d0d76f517fc0" alt="" title=""
                            draggable="false">

                        EpiHelp
                    </a>
                </div>
                <div>
                    <div class="footer__right">
                        <p class="footer__email"><b>{{$t("our_e-mail")}}</b> <a href="mailto:info@epi-help.com"
                                draggable="false">info@epi-help.com</a></p>
                        <div role="form" class="wpcf7" id="wpcf7-f271-o2" lang="en-US" dir="ltr">
                            <div class="screen-reader-response">
                                <p role="status" aria-live="polite" aria-atomic="true"></p>
                                <ul></ul>
                            </div>
                            <form @submit.prevent ="inSubmit"
                                class=" footer-subscribe">
                                <div style="display: none;">
                                    <input type="hidden" name="_wpcf7" value="271">
                                    <input type="hidden" name="_wpcf7_version" value="5.4.2">
                                    <input type="hidden" name="_wpcf7_locale" value="en_US">
                                    <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f271-o2">
                                    <input type="hidden" name="_wpcf7_container_post" value="0">
                                    <input type="hidden" name="_wpcf7_posted_data_hash" value="">
                                </div>
                                <div class="footer-subscribe__row"><span
                                        class="wpcf7-form-control-wrap email-755"><input type="email" name="email-755"
                                            v-model="datasubscribe.email1" size="40"
                                            class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email footer-subscribe__input"
                                            aria-required="true" aria-invalid="false" placeholder="E-mail"></span></div>
                                <button type="submit" @click="inSubmit" class="sbtn footer-subscribe__submit">{{$t("subscribe")}}</button>
                            </form>
                        </div>
                        <p class="footer__writeus">
                            <span><b>{{$t("phone")}}</b> <a href="tel:55555555" draggable="false">555 55 555</a></span>
                            <a href="#support" class="popup-with-zoom-anim sbtn footer__writeus-btn"
                                draggable="false">{{$t("write_us")}}</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="footer__bottom">
                <div>
                    <p class="footer__copyright">{{$t("cooptright")}}</p>
                </div>
                <div>
                    <ul id="menu-footer-menu" class="footer__links">
                        <li id="menu-item-152"
                            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-152"><a
                                href="/privacy-policy" draggable="false">{{$t("privacy")}}</a></li>
                        <li id="menu-item-151"
                            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-151"><a
                                href="/terms-of-user" draggable="false">{{$t("terms_of")}}</a></li>
                        <li id="menu-item-150"
                            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-150"><a
                                href="/cookies" draggable="false">{{$t("cookies")}}</a></li>
                        <li id="menu-item-149"
                            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-149"><a
                                href="/legal" draggable="false">{{$t("legal")}}</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
    <div class="popups">
        <div class="popup popup_1 zoom-anim-dialog mfp-hide" id="support">
            <form @submit.prevent="onSubmit">
                <div style="display: none;">
                    <input type="hidden" name="_wpcf7" value="269">
                    <input type="hidden" name="_wpcf7_version" value="5.4.2">
                    <input type="hidden" name="_wpcf7_locale" value="en_US">
                    <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f269-o4">
                    <input type="hidden" name="_wpcf7_container_post" value="0">
                    <input type="hidden" name="_wpcf7_posted_data_hash" value="">
                </div>
                <h4 class="popup__heading">{{$t("contact_us")}}</h4>
                <div class="popup-form__row">
                    <span class="form-control"><input v-model="datasupport.name" type="text" size="40"
                            class="form-control popup-form__input" :placeholder="$t('name')" required></span>
                </div>
                <div class="popup-form__row">
                    <span class="form-control"><input v-model="datasupport.email" type="email" size="40"
                            class="popup-form__input" :placeholder="$t('email_address')" required></span>
                </div>
                <div class="popup-form__row">
                    <span class="form-control"><input v-model="datasupport.description" type="text" size="40"
                            class="form-control  popup-form__input" :placeholder="$t('descript')" required></span>
                </div>
                <div class="popup-form__row">
                    <span class="form-control">
                        <select v-model="datasupport.type" class="form-select  popup-form__input"
                            aria-label="Default select example">
                            <option value="" disabled selected style="display:none">{{$t("type")}}</option>
                            <option value="Question ">{{$t("menu_questions")}}</option>
                            <option value="Membership and Account">{{$t("membership")}}</option>
                            <option value="App Setup and Using">{{$t("app_setup")}}</option>
                            <option value="Feature Request">{{$t("feature_re")}}</option>
                            <option value="Reports and Payments">{{$t("reports")}}</option>
                            <option value="Lost Password">{{$t("lost_passwords")}}</option>
                            <option value="Report a Concern">{{$t("report_concern")}}</option>
                            <option value="Feedback">{{$t("feedback")}}</option>
                            <option value="Contact us">{{$t("contact")}}</option>
                        </select></span>
                </div>
                <div value="new" style="display:none">{{ datasupport.status = "new" }}</div>
                <button type="submit" @click="onSubmit" class="sbtn close-popup popup-form__submit">{{$t("send")}}</button>
                <div class="  alert  alert-success" id="close-popup" style="display:none"><h2>{{ $t("send_msg") }}</h2></div>
                <div class="wpcf7-response-output" aria-hidden="true"></div>
            </form>
        </div>

        <div class="popup popup_2 zoom-anim-dialog mfp-hide" id="stepbystep">

            <form @submit.prevent="Submit" >
                <div style="display: none;">
                    <input type="hidden" name="_wpcf7" value="269">
                    <input type="hidden" name="_wpcf7_version" value="5.4.2">
                    <input type="hidden" name="_wpcf7_locale" value="en_US">
                    <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f269-o4">
                    <input type="hidden" name="_wpcf7_container_post" value="0">
                    <input type="hidden" name="_wpcf7_posted_data_hash" value="">
                </div>
                <h4 class="popup__heading">{{$t("our_personal")}} <br>{{$t("our_personal1")}}</h4>
                <div class="popup__howcontact">
                    <p class="popup__howcontact-heading">{{$t("how_contact")}}</p>
                    <ul class="popup__howcontact-list">
                        <li><label class="popup__howcontact-cb "><input type="checkbox" v-model="datadonation.skype"
                                    required><span></span><span>Skype</span></label>
                        </li>
                        <li><label class="popup__howcontact-cb "><input type="checkbox" v-model="datadonation.whatsapp"
                                    required><span></span><span>WhatsApp</span></label>
                        </li>
                        <li><label class="popup__howcontact-cb "><input type="checkbox" v-model="datadonation.zoom"
                                    required><span></span><span>ZOOM</span></label>
                        </li>
                        <li><label class="popup__howcontact-cb"> <input type="checkbox" v-model="datadonation.messenger"
                                    required><span></span><span>Messenger</span></label>
                        </li>
                    </ul>
                </div>
                <div class="popup-form__row"><span class="wpcf7-form-control-wrap text-438"><input type="text"
                            name="text" v-model="datadonation.nickname" size="40"
                            class="form-control  popup-form__input" :placeholder="$t('name')" required></span>
                </div>
                <div class="popup-form__row"><span class="wpcf7-form-control-wrap tel-680"><input type="tel"
                            name="tel" v-model="datadonation.phone_number" size="40"
                            class="form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel popup-form__input"
                            :placeholder="$t('your_phone')" required></span>
                </div>
                <button type="submit" @click="Submit()" class="sbtn close-my-popup  popup-form__submit">{{$t("send")}}</button>
                <div class="  alert  alert-success" id="close-my-popup" style="display:none"><h2>Successfully Sent</h2></div>
            </form>
        </div>
        <div class="popup popup_3 zoom-anim-dialog mfp-hide" id="login">
            <ul class="popup__switcher">
                <li class="popup__switcher-item popup__switcher-item_1">User</li>
                <li class="popup__switcher-item popup__switcher-item_2 popup__switcher-item_active">Doctor</li>
            </ul>

            <h4 class="popup__heading">Log in</h4>

            <form class="popup-form">
                <div class="popup-form__row">
                    <input type="email" placeholder="Email" class="popup-form__input" required=true>
                </div>
                <div class="popup-form__row">
                    <input type="password" placeholder="Password" class="popup-form__input" required=true
                        autocomplete="new-password">
                </div>
                <button type="submit" class="sbtn popup-form__submit">Log In</button>
            </form>
        </div>
    </div>
</template>
<script>
import { createSupport, createDonation, createSubscribe } from '@/firebase'
import { reactive } from 'vue'

export default {
  setup () {
    const datasupport = reactive({
      name: '',
      email: '',
      description: '',
      type: '',
      status: ''
    })
    const onSubmit = () => {
      createSupport({ ...datasupport })
      datasupport.description = ''
      datasupport.email = ''
      datasupport.name = ''
      datasupport.type = ''
      datasupport.status = ''
    }
    const datadonation = reactive({
      nickname: '',
      phone_number: '',
      skype: false,
      whatsapp: false,
      zoom: false,
      messenger: false
    })
    const Submit = () => {
      createDonation({ ...datadonation })
      datadonation.nickname = ''
      datadonation.phone_number = ''
      datadonation.skype = false
      datadonation.whatsapp = false
      datadonation.zoom = false
      datadonation.messenger = false
    }
    const datasubscribe = reactive({
      email1: ''
    })
    const inSubmit = () => {
      createSubscribe({ ...datasubscribe })
      datasubscribe.email1 = ''
    }
    return {
      datasupport,
      datadonation,
      datasubscribe,
      onSubmit,
      Submit,
      inSubmit
    }
  }
}

</script>
<style scoped>
.popup__howcontact-cb input[type="checkbox"]:checked + span:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    font-size: 0;
    line-height: 0;
    background: url(https://firebasestorage.googleapis.com/v0/b/epi-help.appspot.com/o/icons%2Fstroke.svg?alt=media&token=b74e3dfc-ff74-4707-82b9-bd5a30f95546) center/17px auto no-repeat;
}
 ::after, ::before {
    box-sizing: border-box;
}
.alert {
    color:greenyellow;
    padding-top: 2%;
    justify-content: center;
}
</style>
