export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приложение Smart Assistant для обнаружения, анализа и предотвращения приступов эпилепсии и не только"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["на русском A Vue i18n next example using vue-i18n-next"])},
  "menu_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компания"])},
  "menu_features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возможности"])},
  "menu_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддержка"])},
  "menu_questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вопросы"])},
  "menu_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])},
  "menu_donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддержать приложение"])},
  "bottom_menu_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Политика конфиденциальности"])},
  "bottom_menu_terms_of_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условия использования"])},
  "bottom_menu_cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файлы cookie"])},
  "bottom_menu_legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правовые"])},
  "use_for_free_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Используйте бесплатно!"])},
  "use_for_free_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EpiHelp улучшает качество жизни"])},
  "install_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Установить сейчас"])},
  "features_div": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Особенности, разработанные для улучшения качества жизни"])},
  "features_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Умное приложение помогает избежать неприятных ситуаций."])},
  "includes_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включает в себя все необходимые функции для накопления информации и контроля беспорядка."])},
  "v_all_features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотреть все функции"])},
  "app_features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возможности приложения"])},
  "smart_algorith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Умный алгоритм. Как он работает"])},
  "the_epihelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мобильное приложение EpiHelp собирает и обрабатывает медицинские данные"])},
  "the_epihelp2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["с помощью двух устройств: iPhone и Apple Watch."])},
  "the_basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основные данные, необходимые для анализа и"])},
  "the_basic2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["передаваемые с Apple Watch:"])},
  "heart_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частота сердцебиения"])},
  "pedometer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шагомер"])},
  "ecg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электрокардиограмма (ЭКГ) (на моделях"])},
  "ecg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["старше WatchOS 5)"])},
  "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Масса"])},
  "add_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительная информация:"])},
  "altitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Версия (на моделях старше WatchOS 5)"])},
  "sleep_monitoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мониторинг сна (если пользователь активировал эту функцию)"])},
  "user_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные пользователя"])},
  "predicting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прогнозирование приступа"])},
  "predicting2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["& Предупреждение пользователя об этом"])},
  "unique_authorship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уникальное авторство"])},
  "the_seizure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Модель прогнозирования припадков анализирует данные, полученные от датчиков Apple Watch, iPhone, а также данные, введенные вручную: либо вами, либо вашим врачом. На основании вашей медицинской карты приложение прогнозирует тип и характеристики потенциальной атаки."])},
  "seizure_tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отслеживание приступов."])},
  "the_mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мобильное приложение может отслеживать два типа падений:"])},
  "fainting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обморок"])},
  "falling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Падение в приступе."])},
  "at_the": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Во время падения приложение автоматически уведомляет всех доверенных лиц, которых вы отметили как опекунов."])},
  "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Система оповещения"])},
  "alert_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Система оповещения работает через push-уведомления (Вы должны включить эту функцию) Передача данных о вероятности приступа близким (Если пользователь активирует эту функцию) Несколько способов информирования близких во время приступа:"])},
  "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст (SMS)"])},
  "phone_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефонный звонок"])},
  "phone_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попытки повтора телефонного звонка"])},
  "app_store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отзывы в магазине приложений"])},
  "view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть все отзывы"])},
  "support_24/7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддержка 24/7"])},
  "choose_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите категорию ниже, чтобы начать"])},
  "mem_acc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Членство и учетная запись"])},
  "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вопрос"])},
  "app_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка приложения и использование"])},
  "feature_re": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрос Функции"])},
  "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчеты и Платежи"])},
  "lost_passwords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забытый Пароль"])},
  "report_concern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщить о проблеме"])},
  "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обратная связь"])},
  "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свяжитесь с нами:"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свяжитесь с нами"])},
  "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Откройте для себя F.A.Q"])},
  "l_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оставьте отзыв — "])},
  "opinion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ваше мнение важно"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])},
  "the_technical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группа технической поддержки поможет вам решить "])},
  "the_technical2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["любую проблему, связанную с эксплуатацией приложения."])},
  "step_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошаговая поддержка: запуск, настройка, помощь в тестировании"])},
  "get_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получите пошаговые инструкции"])},
  "our_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наш контакт"])},
  "clevend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кливленд"])},
  "you_can": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете найти наиболее часто задаваемый вопрос ниже. Если у вас есть вопрос, которого нет в списке, пожалуйста, свяжитесь с нами для получения подробной информации."])},
  "follow_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Следуйте за нами:"])},
  "our_e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наш e-mail:"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наш телефон:"])},
  "write_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напишите нам"])},
  "cooptright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторские права 2021 ЭпиХелп. Все права защищены."])},
  "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Политика конфиденциальности"])},
  "terms_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условия использования"])},
  "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Куки"])},
  "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Законный"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
  "membership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Членство и Учетная запись"])},
  "our_personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наши личные консультанты проведут"])},
  "our_personal1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вас шаг за шагом через весь процесс."])},
  "how_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как с вами связаться?"])},
  "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписывайся"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваше Имя"])},
  "email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронная почта e-mail"])},
  "descript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
  "your_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш номер телефона"])},
  "publications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публикации"])},
  "neurolog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НЕВРОЛОГИЧЕСКИЕ НАРУШЕНИЯ И СОСТОЯНИЯ МОЗГОВОЙ ГЕМОДИНАМИКИ В БОЛЬНЫЕ С ДИСЦИРКУЛЯТОРНОЙ ЭНЦЕФАЛОПАТИИ НА ФОНЕ АРТЕРИАЛЬНОЙ ГИПОТОНИЯ"])},
  "esy_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Простой в использовании"])},
  "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщество"])},
  "send_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно Отправлено"])}
}