<template>
    <div class="s8">
        <div class="container">

            <h3 class="stitle s8__title">{{ $t("support_24/7") }}</h3>
            <div class="s8__box s8__box_1">
                <div>
                    <div class="s8__blocks">
                        <div class="s8__block">
                            <p class="s8__block-text">{{ $t("the_technical") }} <br>{{ $t("the_technical2") }}</p>
                            <ul class="s8__contacts">

                                <li>
                                    <a href="tel:" title="Phone" draggable="false">
                                        <img src="/assets/images/Phone.svg" alt="" title="" draggable="false">
                                    </a>
                                </li>

                                <li>
                                    <a href="mailto:" title="Email" draggable="false">
                                        <img src="/assets/images/email.svg" alt="" title="" draggable="false">
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="s8__block">
                            <p class="s8__block-text">{{ $t("step_by") }}</p>
                            <a href="#stepbystep"
                                class="popup-with-zoom-anim s8__btn s8__btn_1 sbtn sbtn_inv-2"
                                draggable="false">{{ $t("get_step") }}</a>
                        </div>
                        <div class="s8__block">
                            <p class="s8__block-text">F.A.Q.</p>
                            <a href="/questions" class="s8__btn s8__btn_2 sbtn sbtn_inv-2"
                                draggable="false">{{ $t("faq") }}</a>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="s8__img s8__img-1">
                        <img src="/assets/images/1-1.jpg" alt="" title="" draggable="false">
                    </div>
                </div>
            </div>

            <div class="s8__box s8__box_2">
                <div>
                    <div class="s8__img s8__img-2">
                        <img src="/assets/images/2-1.jpg" alt="" title="" draggable="false">
                    </div>
                </div>
                <div>
                    <h3 class="stitle s8__title-2">{{ $t("l_feedback") }}<br>{{ $t("opinion") }}</h3>

                    <div role="form" class="wpcf7" id="wpcf7-f270-o1" lang="en-US" dir="ltr">
                        <div class="screen-reader-response">
                            <p role="status" aria-live="polite" aria-atomic="true"></p>
                            <ul></ul>
                        </div>
                        <form @submit.prevent="Submit">
                            <div class="s8-form__row"><span class="wpcf7-form-control-wrap text-114"><input type="text"
                                        name="text-114" v-model="datafeedback.name" size="40"
                                        class="wpcf7-form-control wpcf7-text s8-form__input"
                                        placeholder="What's your name?" required></span></div>
                            <div class="s8-form__row"><span class="wpcf7-form-control-wrap tel-425"><input type="tel"
                                        name="tel-425" v-model="datafeedback.phone" size="40"
                                        class="wpcf7-form-control wpcf7-text wpcf7-tel  s8-form__input"
                                        placeholder="Your phone:" required></span>
                            </div>
                            <div class="s8-form__row"><span class="wpcf7-form-control-wrap email-245"><input
                                        type="email" name="email-245" v-model="datafeedback.email" size="40"
                                        class="wpcf7-form-control wpcf7-text wpcf7-email  s8-form__input"
                                        placeholder="Your e-mail:" required></span>
                            </div>
                            <div class="s8-form__row"><span class="wpcf7-form-control-wrap textarea-523"><textarea
                                        name="textarea-523" cols="40" rows="10" v-model="datafeedback.comments"
                                        class="wpcf7-form-control wpcf7-textarea s8-form__input s8-form__textarea"
                                        placeholder="Comments" required></textarea></span></div>
                            <button type="submit" @click="Submit" class="sbtn s8-form__submit">{{ $t("send") }}</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createFeedback } from '@/firebase'
import { reactive } from 'vue'

export default {
  setup () {
    const datafeedback = reactive({
      name: '',
      phone: '',
      email: '',
      comments: ''

    })
    const Submit = () => {
      createFeedback({ ...datafeedback })
      datafeedback.name = ''
      datafeedback.phone = ''
      datafeedback.email = ''
      datafeedback.comments = ''
    }
    return { datafeedback, Submit }
  }
}

</script>
