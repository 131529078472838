<template>
  <HeaderDiv />
  <div class="main">
    <CompanyBanerDiv />
    <EpiHelpInfoDiv />
    <ApplicationFeaturesDiv />
    <SmartAlgorithmDiv />
    <WithTheSupportDiv />
    <ReviewsDiv />
    <SupportDiv />
    <UseForFreeDiv />
  </div>
  <FooterDiv />
</template>

<script>

import HeaderDiv from '@/components/HeaderDiv.vue'
import CompanyBanerDiv from '@/components/home/CompanyBanerDiv.vue'
import EpiHelpInfoDiv from '@/components/home/EpiHelpInfoDiv.vue'
import ApplicationFeaturesDiv from '@/components/home/ApplicationFeaturesDiv.vue'
import SmartAlgorithmDiv from '@/components/home/SmartAlgorithmDiv.vue'
import WithTheSupportDiv from '@/components/home/WithTheSupportDiv.vue'
import ReviewsDiv from '@/components/home/ReviewsDiv.vue'
import SupportDiv from '@/components/home/SupportDiv.vue'
import UseForFreeDiv from '@/components/UseForFreeDiv.vue'
import FooterDiv from '@/components/FooterDiv.vue'

export default {
  name: 'HomePage',
  components: {
    CompanyBanerDiv,
    EpiHelpInfoDiv,
    ApplicationFeaturesDiv,
    SmartAlgorithmDiv,
    WithTheSupportDiv,
    ReviewsDiv,
    SupportDiv,
    UseForFreeDiv,
    HeaderDiv,
    FooterDiv
  }
}
</script>
