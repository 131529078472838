<template>
    <div class="s2">
            <div class="container">
                <div class="s2__heading">
                    <h3 class="stitle s2__title" v-html="data[$i18n.locale].title"></h3>
                    <p class="stitle-text s2__subtitle">{{data[$i18n.locale].subtitle}}</p>
                </div>
                <div v-html="data[$i18n.locale].description"></div>
            </div>
            </div>
</template>
<script>
import { getPage } from '@/firebase'
import { reactive, onMounted } from 'vue'

export default {
  setup () {
    const data = reactive({
      ru: {
        title: '',
        subtitle: '',
        description: ''
      },
      en: {
        title: '',
        subtitle: '',
        description: ''
      },
      uz: {
        title: '',
        subtitle: '',
        description: ''
      }
    })
    onMounted(async () => {
      const page = await getPage('nHT1Hk36kjHegeTDbmbv')
      // eslint-disable-next-line dot-notation
      data['ru'].title = page.title_ru
      // eslint-disable-next-line dot-notation
      data['ru'].subtitle = page.subtitle_ru
      // eslint-disable-next-line dot-notation
      data['ru'].description = page.description_ru
      // eslint-disable-next-line dot-notation
      data['en'].title = page.title_en
      // eslint-disable-next-line dot-notation
      data['en'].subtitle = page.subtitle_en
      // eslint-disable-next-line dot-notation
      data['en'].description = page.description_en

      // eslint-disable-next-line dot-notation
      data['uz'].title = page.title_uz
      // eslint-disable-next-line dot-notation
      data['uz'].subtitle = page.subtitle_uz
      // eslint-disable-next-line dot-notation
      data['uz'].description = page.description_uz
    })
    return { data }
  }

}
</script>
