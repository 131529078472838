<template>
    <div class="s4">
            <div class="container">

                <div class="s4__heading">
                    <h3 class="stitle s4__title">{{$t("smart_algorith")}}</h3>
                    <p class="stitle-text s4__subtitle">{{$t("the_epihelp")}} <br>{{$t("the_epihelp2")}}</p>
                </div>

                <div class="s4__blocks">

                    <div class="s4__block">
                        <h4>{{$t("the_basic")}}<br>
                            {{$t("the_basic2")}}</h4>
                        <ul>
                            <li>{{$t("heart_rate")}}</li>
                            <li>{{$t( "pedometer")}}</li>
                            <li>{{$t("ecg")}}<br>
                                {{$t("ecg2")}}</li>
                            <li>{{$t("weight")}}</li>
                        </ul>

                    </div>

                    <div class="s4__block">
                        <h4>{{$t("add_date")}}</h4>
                        <ul>
                            <li>{{$t("altitude")}}</li>
                            <li>{{$t("sleep_monitoring")}}</li>
                            <li>{{$t("user_data")}}</li>
                        </ul>

                    </div>

                    <h3>{{$t("predicting")}} <br>{{$t("predicting2")}}</h3>

                    <div class="s4__block">
                        <h4>{{$t("unique_authorship")}}</h4>
                        <p>{{$t("the_seizure")}}.</p>

                    </div>

                    <div class="s4__block">
                        <h4>{{$t("seizure_tracking")}}</h4>
                        <p>{{$t("the_mobile")}}</p>
                        <ul>
                            <li>{{$t("fainting")}}</li>
                            <li>{{$t("falling")}}</li>
                        </ul>
                        <p>{{$t("at_the")}}</p>

                    </div>

                    <h3>{{$t("alert")}}</h3>
                    <img src="https://firebasestorage.googleapis.com/v0/b/epi-help.appspot.com/o/imagesStaticPage%2Fhand.jpg?alt=media&token=181bba0a-4345-4260-84de-01853082cd17" alt="" title="" draggable="false">

                    <div class="s4__block s4__block_full">
                        <p>{{$t("alert_info")}}</p>
                        <ul>
                            <li>{{$t("text")}}</li>
                            <li>{{$t("phone_call")}}</li>
                            <li>E-mail</li>
                            <li>{{$t("phone_attempts")}}</li>
                        </ul>

                    </div>

                </div>
            </div>
        </div>
</template>
