<template>
    <div class="s6">
            <div class="container" v-for="{ id,content, image } in support" :key="id">
                <h3 class="stitle s6__title" >{{content[$i18n.locale].title}}</h3>
                <div class="s6-emblem">
                    <div class="s6-emblem__img">
                        <img :src="image" alt="" title="" draggable="false">
                    </div>
                    <p class="s6-emblem__text" v-html="content[$i18n.locale].description"></p>
                </div>
            </div>
            </div>
</template>
<script>
import { useLoadSupport } from '@/firebase'

export default {
  setup () {
    const support = useLoadSupport(100)
    return { support }
  }
}
</script>
