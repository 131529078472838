export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Epilepsiya tutqanoqlarini aniqlash, tahlil qilish, oldini olish va undan tashqarida aqlli yordamchi ilova"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" A Vue i18n next example using vue-i18n-next"])},
  "menu_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompaniya"])},
  "menu_features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xususiyatlar"])},
  "menu_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qo'llab-quvvatlash"])},
  "menu_questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Savollar"])},
  "menu_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktlar"])},
  "menu_donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilovaga xayriya"])},
  "bottom_menu_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maxfiylik siyosati"])},
  "bottom_menu_terms_of_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foydalanish shartlari"])},
  "bottom_menu_cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies"])},
  "bottom_menu_legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huquqiy"])},
  "use_for_free_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bepul foydalaning!"])},
  "use_for_free_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EpiHelp hayot sifatini yaxshilaydi"])},
  "install_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozir o'rnatish"])},
  "features_div": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hayot sifatini yaxshilash uchun mo'ljallangan xususiyatlar "])},
  "features_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aqlli dastur noxush vaziyatlardan qochishga yordam beradi."])},
  "includes_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Axborot yig'ish va tartibsizlikni nazorat qilish uchun barcha zarur funktsiyalarni o'z ichiga oladi."])},
  "v_all_features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all features"])},
  "app_features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilova xususiyatlari"])},
  "smart_algorith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aqlli algoritm. U qanday ishlaydi"])},
  "the_epihelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EpiHelp mobil ilovasi ikkita qurilma: iPhone va Apple Watch yordamida tibbiy"])},
  "the_epihelp2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ma'lumotlarni to'playdi va qayta ishlaydi."])},
  "the_basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahlil qilish uchun zarur bo'lgan va "])},
  "the_basic2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apple Watch'dan uzatiladigan asosiy ma'lumotlar:"])},
  "heart_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yurak urish tezligi"])},
  "pedometer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedometer"])},
  "ecg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektrokardiogramma (EKG) "])},
  "ecg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WatchOS 5 dan eski modellarda)"])},
  "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Og'irligi"])},
  "add_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qo'shimcha ma'lumotlar:"])},
  "altitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versiya (WatchOS 5 dan eski modellarda)"])},
  "sleep_monitoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uyqu monitoringi (agar foydalanuvchi ushbu xususiyatni yoqgan bo'lsa)"])},
  "user_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foydalanuvchi ma'lumotlari"])},
  "predicting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Epilepsiya tutilishini prognoz qilish "])},
  "predicting2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["& Foydalanuvchini ogohlantirish"])},
  "unique_authorship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noyob mualliflik"])},
  "the_seizure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Epilepsiyani bashorat qilish modeli Apple Watch sensorlari, iPhone’lar va siz yoki shifokoringiz tomonidan qo‘lda kiritilgan ma’lumotlarni tahlil qiladi. Sizning tibbiy tarixingizga asoslanib, ilova ehtimoliy hujumning turi va xususiyatlarini prognoz qiladi."])},
  "seizure_tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutqichni kuzatish."])},
  "the_mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobil ilova ikki turdagi tushishni kuzatishi mumkin:"])},
  "fainting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hushidan ketish"])},
  "falling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutqichga tushish"])},
  "at_the": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yiqilish vaqtida ilova siz g'amxo'rlik qiluvchi sifatida belgilagan barcha ishonchli odamlarni avtomatik ravishda xabardor qiladi."])},
  "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xabar berish tizimi"])},
  "alert_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogohlantirish tizimi push-bildirishnomalar orqali ishlaydi (Siz ushbu xususiyatni yoqishingiz kerak) Yaqinlaringizga hujum ehtimoli haqida ma'lumot yuborish (Agar foydalanuvchi ushbu xususiyatni faollashtirsa) Hujum paytida yaqinlaringizni xabardor qilishning bir necha usullari:"])},
  "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matn (SMS)"])},
  "phone_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon qo'ng'irog'i"])},
  "phone_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon qo'ng'irog'iga qayta urinishlar"])},
  "app_store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilovalar do'koni sharhlari"])},
  "view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barcha sharhlarni ko'ring"])},
  "support_24/7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qo'llab-quvvatlash 24/7"])},
  "choose_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boshlash uchun quyida toifani tanlang"])},
  "mem_acc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A'zolik va Qayd yozuvi"])},
  "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Savol"])},
  "app_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilovani sozlash va undan foydalanish"])},
  "feature_re": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xususiyat So'rovi"])},
  "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hisobotlar va To'lovlar"])},
  "lost_passwords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yo'qolgan Parol"])},
  "report_concern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muammo haqida xabar bering"])},
  "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qayta Aloqa"])},
  "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biz bilan bog'lanish:"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biz bilan bog'lanish"])},
  "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["F.A.Q. bilan tanishing"])},
  "l_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fikr qoldiring — "])},
  "opinion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fikringiz muhim"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yuborish"])},
  "the_technical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texnik yordam jamoasi sizga har qanday operatsion "])},
  "the_technical2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dastur bilan bog'liq muammoda yordam beradi."])},
  "step_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Bosqichma-bosqich qo'llab-quvvatlash: ishga tushirish, sozlash, test yordami"])},
  "get_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosqichma-bosqich ko'rsatmalar oling"])},
  "our_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizning aloqamiz"])},
  "clevend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klivlend"])},
  "you_can": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quyida eng ko'p beriladigan savolni topishingiz mumkin. Agar sizda ro'yxatda bo'lmagan savol bo'lsa, qo'shimcha ma'lumot olish uchun bizga murojaat qiling."])},
  "follow_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizni kuzatib boring:"])},
  "our_e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizning e-mail:"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizning telefonimiz:"])},
  "write_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizga yozing"])},
  "cooptright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mualliflik huquqi © 2021 EpiHelp. Barcha huquqlar himoyalangan."])},
  "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maxfiylik siyosati"])},
  "terms_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foydalanish shartlari"])},
  "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuki"])},
  "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qonuniy"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turi"])},
  "membership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A'zolik va  Qayd yozuvi"])},
  "our_personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizning shaxsiy maslahatchilarimiz sizni"])},
  "our_personal1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["butun jarayon bo'yicha bosqichma-bosqich yo'naltiradi."])},
  "how_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siz bilan qanday bog'lanish mumkin?"])},
  "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obuna bo'lish"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ismingiz"])},
  "email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elekton pochtangiz e-mail"])},
  "descript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tavsif"])},
  "your_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon raqamingiz"])},
  "publications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nashrlar"])},
  "neurolog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARTERIYAL GIPOTANSIYA FOYDAGI DISKIRKULYATORIY ENSEFALOPATİYASI BASORLARDA NEVROLOGIK BUZILGANLAR VA MIYA GEMODINAMIKASI SHARTLARI."])},
  "esy_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foydalanish uchun oson"])},
  "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jamiyat"])},
  "send_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muvaffaqiyatli yuborildi"])}
}