<!-- <template>
    <div class="s3">
        <div class="container">
            <h3 class="stitle s3__title">{{$t("app_features")}}</h3>
            <ul class="s3__list">
                <li>
                    <article class="s3-item">
                        <div class="s3-item__main">
                            <h4 class="s3-item__title" v-html="data[$i18n.locale].title"></h4>
                            <p class="s3-item__text" v-html="data[$i18n.locale].description" ></p>
                            <p>Eto foto: {{data['image']}}</p>
                        </div>
                        <div class="s3-item__img">
                            <span></span>
                            <img :src="data['image']" alt="..." class="img-thumbnail"/>
                            <!-mg src="https://epi-help.com/wp-content/uploads/2021/07/3.jpg" alt="" title="" draggable="false"> -->
                        <!-- </div>
                    </article>
                </li>
            </ul>
            <a href="https://epi-help.com/features/" class="sbtn s3__view-all" draggable="false">{{$t("v_all_features")}}</a>
        </div>
    </div>
</template>

<script>
import { getPageFeature } from '@/firebase'
import { reactive, onMounted } from 'vue'

export default {
  setup () {
    const data = reactive({
      image: '',
      ru: {
        title: '',
        image: '',
        description: ''
      },
      en: {
        title: '',
        image: '',
        description: ''
      },
      uz: {
        title: '',
        image: '',
        description: ''
      }
    })
    onMounted(async () => {
      const page = await getPageFeature('altitude_changes_analysis')
      // eslint-disable-next-line dot-notation
      data['ru'].title = page.title_ru
      // eslint-disable-next-line dot-notation
      data['ru'].image = page.image
      // eslint-disable-next-line dot-notation
      data['ru'].description = page.description_ru
      // eslint-disable-next-line dot-notation
      data['en'].title = page.title_en
      // eslint-disable-next-line dot-notation
      data.image = page.image
      // eslint-disable-next-line dot-notation
      data['en'].description = page.description_en
      // eslint-disable-next-line dot-notation
      data['uz'].title = page.title_uz
      // eslint-disable-next-line dot-notation
      data['uz'].image = page.image
      // eslint-disable-next-line dot-notation
      data['uz'].description = page.description_uz
    })
    return { data }
  }

}
</script> --> -->
<template>
  <div class="s3">
    <div class="container">
      <h3 class="stitle s3__title">{{ $t("app_features") }}</h3>
      <ul class="s3__list">
        <li v-for="{ id, content, image } in features" :key="id">
          <article class="s3-item">
            <div class="s3-item__main">
              <h4 class="s3-item__title">{{ content[$i18n.locale].title }}</h4>
              <p class="s3-item__text">{{ content[$i18n.locale].description }}</p>
            </div>
            <div class="s3-item__img">
              <span></span>
              <img :src="image" alt="" title="" draggable="false">
            </div>
          </article>
        </li>
      </ul>
      <a href="/features" class="sbtn s3__view-all" draggable="false">{{ $t("v_all_features") }}</a>
    </div>
  </div>

</template>

<script>
import { useLoadFeatures } from '@/firebase'

export default {
  setup () {
    const features = useLoadFeatures(8)
    return { features }
  }
}
</script>
