<template>
    <header class="header">
        <div class="header__nav-bg"></div>
        <div class="container">
            <div class="header__nav">

                <router-link to="/" class="header__logo" draggable="false">
                    <img src="https://firebasestorage.googleapis.com/v0/b/epi-help.appspot.com/o/icons%2FLogo.svg?alt=media&token=00cb3fd2-28dd-4e1d-b85a-d0d76f517fc0" alt="" title=""
                        draggable="false">
                </router-link>

                <ul id="menu-header-menu" class="header__menu ">
                    <li id="menu-item-24"
                        class="menu-item menu-item-type-post_type menu-item-object-page page_item page-item-7 current_page_item menu-item-24">
                        <router-link to="/company">{{ $t('menu_company') }}</router-link></li>
                    <li id="menu-item-23" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-23">
                        <router-link to="/features">{{ $t('menu_features') }}</router-link></li>
                     <li id="menu-item-22" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-22">
                        <router-link to="/support">{{ $t('menu_support') }}</router-link></li>
                    <li id="menu-item-21" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-21">
                        <router-link to="/questions">{{ $t('menu_questions') }}</router-link></li>
                    <li id="menu-item-20" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-20">
                        <router-link to="/contacts">{{ $t('menu_contact') }}</router-link></li>
                        <li><LocaleSwitcher /></li>
                    <!-- <li id="menu-item-24"
                        class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-7 current_page_item menu-item-24">
                        <a href="https://epi-help.com/company/" aria-current="page" draggable="false">Company</a></li>
                    <li id="menu-item-23" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-23">
                        <a href="https://epi-help.com/features/" draggable="false">Features</a></li> -->
                    <!-- <li id="menu-item-22" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-22">
                        <a href="https://epi-help.com/support/" draggable="false">Support</a></li>
                    <li id="menu-item-21" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-21">
                        <a href="https://epi-help.com/questions/" draggable="false">Questions</a></li>
                    <li id="menu-item-20" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-20">
                        <a href="https://epi-help.com/contact/" draggable="false">Contact</a></li>
                    <li id="menu-item-19" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-19">
                        <a href="https://epi-help.com/resources/" draggable="false">Resources</a></li> -->
                    </ul>

                <a href="#stepbystep" class="popup-with-zoom-anim sbtn header__donation" draggable="false">
                    <span>{{$t('menu_donate')}}</span>
                </a>

                <ul class="header__btns">
                    <li>
                        <a href="#support" class="popup-with-zoom-anim header__btn header__support"
                            draggable="false">{{ $t('menu_support') }} 24/7</a>
                    </li>
                    <li>
                        <a href="#login" class="popup-with-zoom-anim header__btn header__account"
                            draggable="false">Account</a>
                    </li>

                </ul>

            </div>
        </div>

        <div class="header__menu-btn-wrapper ">
            <button class="hamburger hamburger--3dx header__menu-btn" type="button">
                <span class="hamburger-box">
                    <span class="hamburger-inner"></span>
                </span>
            </button>
        </div>
    </header>

</template>
<script>
import LocaleSwitcher from './LocaleSwitcher.vue'

export default {
  name: 'HelloI18n',
  components: { LocaleSwitcher }
}
</script>
