
<template>
    <a >
      <select class="select_model" v-model="$i18n.locale">
      <option v-for="(locale, i) in locales" :key="`locale-${i}`" :value="locale" class="material-symbols-outlined">
        {{ locale }}
      </option>
      </select></a>
</template>
    <!-- <select class="select_model" v-model="$i18n.locale">
      <option v-for="(locale, i) in locales" :key="`locale-${i}`" :value="locale" class="material-symbols-outlined">
        {{ locale }}
      </option>
      </select>
  </template> -->
<script>
export default {
  // eslint-disable-next-line quotes
  name: 'LocaleSwitcher',
  data () {
    // eslint-disable-next-line quotes
    return { locales: ["ru", "en", "uz"] }
  }
}
</script>

<style>
.material-symbols-outlined{
  color:#ffff;

}
.select_model{
  color:#ffff;
  background: #BAE637;
  border-radius: 25%;

}
.image {
  width:40px;
  position: relative;
  height: 40px;
  justify-content: center;
}
</style> -->
