/* eslint-disable indent */
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [

    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/company',
        name: 'company',
        component: () => import('../views/CompanyView.vue')
    },
    {
        path: '/reviews',
        name: 'review',
        component: () => import('../views/ReviewView.vue')

    },
    {
        path: '/features',
        name: 'features',
        component: () => import('../views/FeaturesView.vue')
    },
    {
        path: '/support',
        name: 'support',
        component: () => import('../views/SupportView.vue')
    },
    {
        path: '/questions',
        name: 'Questions',
        component: () => import('../views/QuestionsView.vue')
    },
    {
        path: '/contacts',
        name: 'Contacts',
        component: () => import('../views/ContactsView.vue')
    },
    {
        path: '/publications',
        name: 'Publications',
        component: () => import('../views/PublicationsView.vue')
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },

    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: () => import('../views/PrivacyPolicyView.vue')
    },

    {
        path: '/terms-of-user',
        name: 'TermsOfUse',
        component: () => import('../views/TermsOfUseView.vue')
    },
    {
        path: '/cookies',
        name: 'Cookies',
        component: () => import('../views/CookiesView.vue')
    },
    {
        path: '/legal',
        name: 'Legal',
        component: () => import('../views/LegalView.vue')
    }
]

const router = createRouter({
    mode: 'history',
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
